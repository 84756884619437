import React, {useContext, useEffect, useState} from "react";

import './main.css';
import "./modal.css"
import {Link} from "react-router-dom";
import Modal from "./Modal";
import {ModalContext} from "../context/ModalContext";
import {GameIdContext} from "../context/GameIdContext";
import logo1 from "../media/xo-game.svg";


const XOGameList = () => {
    const modalActive = useContext(ModalContext).modalActive;
    const setModalActive = useContext(ModalContext).setModalActive;
    const gameId = useContext(GameIdContext).gameId;
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const gamesReducer = (state, action) => {
        return action
    };
    const [XOGameList, dispatchGames] = React.useReducer(
        gamesReducer,
        []
    );
    const getXOGameList = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };
        const response = await fetch(`/api/xo_game?search=${searchTerm}`, requestOptions);
        const data = await response.json();


        if (!response.ok) {
            console.log("something messed up");
        } else {
            dispatchGames(data)
        }
    };


    useEffect(() => {
        getXOGameList();
    }, [searchTerm]);

    return (
        <div id={"xo-game-list"}>
            <div>
                <h2>List of XO Games</h2>
                <form className={"input-atom"}>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleChange}
                        placeholder="Inter game name..."
                    />
                </form>
                <GameList gameList={XOGameList}/>
                <Link to={"/create_xo_game"}>
                    <button className={"button"} type="button">Create XO Game</button>
                </Link>
                <Modal active={modalActive} setActive={setModalActive}>
                    <div className={modalActive ? "modal-content active" : "modal-content"}>
                        <p>Attention, you want to start the game with a bet. Before the game starts, the bet amount will
                            be
                            debited
                            from your account. At the end of the game, if you win, your bet will be returned to your
                            account
                            and
                            your winnings will be credited equal to the amount of bets of other players. If you lose,
                            your
                            bet
                            is
                            lost.
                            <br/>
                            Attention! Check your stable Internet connection and be attentive to the game, if there is
                            no
                            activity
                            for three minutes, you will automatically lose the game.</p>
                        <button type="button" onClick={() =>
                            setModalActive(_ => false)}>Cancel
                        </button>
                        <Link to={"/xo_game/" + gameId}>
                            <button type="button"
                                    onClick={() => setModalActive(_ => false)}>
                                Continue
                            </button>
                        </Link>
                    </div>
                </Modal>
            </div>
            <div id={"xo-game-info"}>
                <img id={"xo-game-image"} src={logo1} alt="img"/>
                <p>This simple game of tic-tac-toe will help you get acquainted with our service, or pass the time
                    :)</p>
            </div>
        </div>
    );
};

const GameList = ({gameList}) => {
    return (<div id={"xo-game-list-scroll"}>
        {gameList.map(game => (
            <div key={game.id}>
                <Game
                    game={game}
                />
            </div>
        ))}
    </div>)
}

const Game = ({game}) => {
    const setModalActive = useContext(ModalContext).setModalActive;
    const setGameId = useContext(GameIdContext).setGameId;
    return (
        <div className="game-visit-card">
            <div>
                <h3>Name: {game.name}</h3>
                <hr style={{border: "2px solid", width: "200px", borderRadius: "3px"}}/>
                <h3>Bet: {game.bet_for_player}</h3>
            </div>
            <div>
                <button className={"button"} type="button" onClick={() => {
                    setGameId(_ => game.id);
                    setModalActive(true);
                }}>
                    Play!
                </button>
            </div>

        </div>
    );
}
export default XOGameList;