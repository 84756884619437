import React, {useState} from "react";
import {Link} from "react-router-dom";
import logo1 from '../media/karts.png';
import logo2 from '../media/xo-game.png';


const Main = () => {
    const slides = [
        <div className="slide">
            <img className={"welcome-image"} src={logo1} alt="img"/>
            <Link id={"xo-game-list-link"} to={"/xo_game_list"}>
                <h3>List of XO Games</h3>
            </Link>
        </div>,
        <div className="slide">
            <img className={"welcome-image"} src={logo2} alt="img"/>
            <Link id={"xo-game-list-link"} to={"/xo_game_list"}>
                <h3>List of XO Games</h3>
            </Link>
        </div>
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    return (
        <div id={"welcome-page"}>
            <div id="welcome-message-container">
                <div id="welcome-message">
                    <h2>
                        Играйте, общайтесь
                        <br/>находите друзей и эмоции
                        <div style={{width: "70%", marginLeft: "auto", marginRight: "auto"}}>
                            <hr style={{border: "2px solid", borderRadius: "3px"}}/>
                        </div>
                        Play, chat
                        <br/>find friends and emotions
                    </h2>
                </div>
            </div>
            <div className="welcome-game-container">
                <div className="game-choice-button-container">
                    <button className="game-choice-button" onClick={goToPrevious}>&lt;</button>
                </div>
                <div className={"slider-container"}>
                    <div className="slider" style={{transform: `translateX(-${currentIndex * 100}%)`}}>
                        {slides}
                    </div>
                </div>
                <div className="game-choice-button-container">
                    <button className="game-choice-button" onClick={goToNext}>&gt;</button>
                </div>
            </div>


        </div>
    );
};

export default Main;
