import edit_icon from "../media/edit-icon.svg";
import React, {useState, useRef} from "react";

const FileUpload = ({allowedTypes, downloadButtonInfo, metaFunc}) => {
    const inputFile = useRef(null);

    const handleFileUpload = async e => {
        if (metaFunc != null){
            await metaFunc(e);
        }

    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    return (
        <div>
            <span id={"file-upload-container"}>
                <input
                    style={{display: "none"}}
                    accept={allowedTypes}
                    ref={inputFile}
                    onChange={handleFileUpload}
                    type="file"
                />
                <img onClick={onButtonClick}
                     src={edit_icon}
                />
                <h4 onClick={onButtonClick}>
                    {downloadButtonInfo}
                </h4>
            </span>
        </div>
    );
};

export default FileUpload;
