import React, {useContext, useState} from "react";

import {UserContext} from "../context/UserContext";
import {Link} from "react-router-dom";
import Modal from "./Modal";
import {ModalProfileContext} from "../context/ModalProfileContext";
import logo1 from "../media/modal-profile-image.svg";
import plus_logo from "../media/plus-logo.svg";
import Logout from "./Logout";
import FileUpload from "./FileUpload";

const Header = () => {
    const [refillAmount, setRefillAmount] = useState("");
    const token = useContext(UserContext).token;
    const modalProfileActive = useContext(ModalProfileContext).modalProfileActive;
    const setModalProfileActive = useContext(ModalProfileContext).setModalProfileActive;
    const profileReducer = (state, action) => {
        return action
    };
    const [profile, dispatchProfile] = React.useReducer(
        profileReducer,
        {}
    );
    const getProfile = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const response = await fetch("/api/auth/user/me", requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log("something messed up");
        } else {
            dispatchProfile(data)
        }
    };
    const balanceRefill = async (event) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const response = await fetch(`/api/auth/user/balance/refill?amount=${refillAmount}`, requestOptions);
        if (!response.ok) {
            const inputElement = document.getElementById('refill-form');
            inputElement.classList.add('shake');
            // Удаляем класс анимации после завершения анимации
            setTimeout(function () {
                inputElement.classList.remove('shake');
            }, 500);
            return
        }
        await getProfile();
        setRefillAmount('')
    };

    const handleBalanceRefill = (e) => {
        e.preventDefault();
        balanceRefill();
    };

    const profilePhotoUpload = async (e) => {
        const formData = new FormData();
        const {files} = e.target;
        if (files && files.length) {
            const requestOptions = {
                method: "POST",
                body: formData
            };
            const file = files[0]
            formData.append('file', file, file.name);
            // -----------------validation-------------------
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = async function () {
                const width = img.width;
                const height = img.height;
                const aspectRatio = height / width;

                // Пример: допустимые пропорции от 1:1 до 16:9
                const minAspectRatio = 1;
                const maxAspectRatio = 16 / 9;

                if (aspectRatio >= minAspectRatio && aspectRatio <= maxAspectRatio) {
                    console.log()
                } else {
                    alert('Неподдерживаемые пропорции изображения. Пожалуйста выберите файл с пропорциями между 1:1 и 16:9.' +
                        '\nImage proportions are not acceptable. Please select an image with proportions between 1:1 and 16:9');
                    URL.revokeObjectURL(img.src);
                    return;
                }
                URL.revokeObjectURL(img.src);
                const response = await fetch(`/file-api/v1/file/`, requestOptions);
                const fileId = (await response.json()).id
                await attachProfilePhoto(fileId)
            };
            // -----------------end-of-validation-------------------
        }
    };
    const attachProfilePhoto = async (fileId) => {
        const requestOptions = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({profile_photo_id: fileId})
        };

        const response = await fetch("/api/auth/user", requestOptions);
        if (response.ok){
            dispatchProfile({"profile_photo_id": fileId})
        } else {
            alert("Что-то пошло не так, поторите попытку снова." +
                "\nSomething went wrong, please try again.")
        }

    };

    return (
        <div id={"header"}>
            {!token ? (
                <>
                    <Link to={"/login"}>
                        <button className={"button"} type="button">Login</button>
                    </Link>
                    <Link to={"/register"}>
                        <button className={"button"} type="button">Register</button>
                    </Link>
                </>
            ) : (
                <div className={"profile"} title={'Your profile'} onClick={() => {
                    getProfile();
                    setModalProfileActive(true);
                }}>
                </div>
            )}
            <Modal active={modalProfileActive} setActive={setModalProfileActive}>
                <div className={modalProfileActive ? "modal-content-profile active" : "modal-content-profile"}>
                    <div>
                        <div id={"profile-image-container"}>
                            <img id={"modal-profile-image"} src={`/file-api/v1/file/${profile.profile_photo_id}/`}
                                 alt={logo1}/>
                        </div>
                        <div id={"avatar-edit"}>
                            <FileUpload allowedTypes={[".png", ".jpeg", ".jpg"]}
                                        downloadButtonInfo="Сменить аватар"
                                        metaFunc={profilePhotoUpload}
                                        postActionFunc={attachProfilePhoto}
                            />
                        </div>
                    </div>
                    <div id={"modal-profile-info"}>
                        <h4>{profile.name} {profile.surname}</h4>
                        <h4>{profile.email}</h4>
                        <h4>Balance {profile.balance} coins</h4>
                        <h4>Total games {profile.games_count}</h4>
                        <h4>Total wins {profile.wins_count}</h4>
                        <form className="custom-balance-manipulation-form"
                              id={'refill-form'}
                              onSubmit={(event) => {
                                  handleBalanceRefill(event);
                              }}>
                            <div>
                                <input type="number"
                                       min={100}
                                       placeholder={"Refill balance"}
                                       value={refillAmount}
                                       onChange={(e) => setRefillAmount(e.target.value)}/>
                                <button type="submit">
                                    <img src={plus_logo} alt="img"/>
                                </button>
                            </div>
                        </form>
                        <div style={{marginBottom:"50px",marginTop:"50px"}}>
                            <Logout/>
                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Header;
