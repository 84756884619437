import React from "react";


const About = () => {
    return (
        <div className={"info-page"}>
            <div>
                <p>
                    Our team has been actively developing the project since 2023, the idea appeared in 2019. The
                    platform will provide users with the opportunity to play a board game of their choice. Each user's
                    profile will display their rating, the amount of in-game currency, the number of games, wins and
                    losses with all the statistics, etc. Various tournaments will also be held, where the winners will
                    receive special prizes (a vacation package, a car, or maybe a brand new smartphone and much more).
                </p>
                <p>
                    At the moment the project is running in a demo version and has one tic-tac-toe game available.
                    This is necessary for debugging and safe launch of the project, but you can register now and get
                    acquainted with the project!
                </p>
                <p>
                    In addition to the basic functionality of working in your personal account and the game itself, we
                    will develop the project in the direction of a convenient, pleasant interface, a choice of many
                    games, additional functionality (chat for communication during the game), protection from new cyber
                    threats and data leaks. We are open to your wishes and suggestions and will be grateful for
                    criticism.
                </p>
                <p>
                    The project is constructed on absolute trust and your enthusiasm to play and win.
                    We will do everything in terms of safety and reliability to deserve it.
                    Have a nice time!
                </p>
                <hr/>
                <p>
                    Наша команда ведет активную разработку проекта с 2023 года, идея появилась в 2019 году. Платформа
                    предоставит пользователям возможность сыграть в выбраную настольную игру. У каждого пользователя в
                    профиле будет отображаться его рейтинг, количество внутриигровой валюты, количество игр, побед и
                    проигрышей со всей статистикой и тд. Также будут проводится различного рода турниры,
                    где победителям достанутся особые призы(путевка на отдых, автомобиль или может новенький смартфон и
                    много подобного).
                </p>
                <p>
                    В данный момент проект запущен в демо версии и имеет одну доступную игру крестики-нолики.
                    Это необходимо для отладки и безопасного запуска проекта, но вы уже сейчас можете регистрироваться и
                    знакомится с проектом!
                </p>
                <p>
                    Помимо основного функционала работы в личном кабинете и непосредственно игры, мы будем развивать
                    проект в направлении удобного приятного интерфейса, выбора множества игр, дополнительного
                    функционала(чат для общения во время игры), защиты от новых киберугроз и утечки данных.
                    Мы открыты к вашим пожеланиям и предложениям и будем благодарны за критику.
                </p>
                <p>
                    Проект построен на абсолютном доверии и вашем интузиазме играть и выигрывать.
                    Мы сделаем все в плане безопасности и надежности чтобы его заслужить.
                    Приятного времяпровождения!
                </p>
            </div>
        </div>
    );
};

export default About;
