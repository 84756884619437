import React, {useState, useContext} from "react";

import ErrorMessage from "./ErrorMessage";
import {UserContext} from "../context/UserContext";
import {useNavigate} from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const setToken = useContext(UserContext).setToken;
    const setUserId = useContext(UserContext).setUserId;

    const submitLogin = async () => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: JSON.stringify(
                `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
            ),
        };

        const response = await fetch("/api/auth/token", requestOptions);

        const data = await response.json();


        if (!response.ok) {
            setErrorMessage(data.detail);
        } else {
            setToken(data.access_token);
            const userRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + data.access_token,
                },
            };
            const userResponse = await fetch("/api/auth/user/me", userRequestOptions);
            const userData = await userResponse.json();
            setUserId(userData.user_id);
            navigate("/xo_game_list")
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitLogin();
    };

    const handleResendConfirmationEmail = async () => {
        const requestOptions = {
            method: "GET"
        };
        await fetch(`api/auth/user/resend_confirmation_email?email=${email}`, requestOptions);
        setErrorMessage(null)
    };
    const resendEmailLinkStyle = errorMessage==='Account is not confirmed' ? 'visible' : 'hidden'

    return (
            <form className={"form"} onSubmit={handleSubmit}>
                <div className={"form-content"}>
                    <div>
                        <div className={"input-atom"}>
                            <label>Email Address</label>
                            <input
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className={"input-atom"}>
                            <label>Password</label>
                            <input
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <ErrorMessage message={errorMessage}/>
                    <p id={"resend-email-link"} style={{visibility: resendEmailLinkStyle}} onClick={handleResendConfirmationEmail}>
                        Resend confirmation email to the specified address</p>
                    <button className={"input-form-button"} type="submit">
                        Login
                    </button>
                </div>
            </form>
    );
};

export default Login;
